<template>
  <v-container fluid>
    <h1 class="text-h5 primary--text pa-3 mb-7">Dashboard</h1>

    <v-row>
      <v-col
        v-for="(card, idx) in cards"
        :key="idx"
        cols="12"
        md="3"
      >
        <SimpleCard
          :title="card.title"
          :icon="card.icon"
          :amount="card.amount"
          :color="card.color"
          :dark="card.dark"
          :active="card.active"
          :inactive="card.inactive"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        v-for="(tableItem, idx) in tableItems"
        :key="idx"
        cols="12"
        md="6"
      >
        <TableCard
          :title="tableItem.title"
          :color="tableItem.color"
          :headers="tableItem.headers"
          :items="tableItem.items"
          :icon="tableItem.icon"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import checkProfileRoutePermission from '@/mixins/checkProfileRoutePermission'
export default {
  name: 'stockDashboard',
  components: {
    SimpleCard: () => import('@/components/dashboard/SimpleCard'),
    TableCard: () => import('@/components/dashboard/TableCard'),
  },
  mixins: [checkProfileRoutePermission],
  data: () => ({
    tableItems: [
      {
        title: 'Produtos por gravação',
        icon: 'fas fa-stamp',
        color: 'error-color',
        headers: [
          { text: 'Gravação', value: 'print_name' },
          { text: 'Produtos', value: 'product_amount', align: 'center' },
        ],
        items: [],
      },
      {
        title: 'Produtos por categoria',
        icon: 'fas fa-th',
        color: 'primary-color',
        headers: [
          { text: 'Categoria', value: 'categories.name' },
          { text: 'Produtos', value: 'product_amount', align: 'center' },
        ],
        items: [],
      },
    ],
    cards: [
      {
        title: 'Produtos',
        icon: 'fas fa-box',
        amount: 0,
        color: 'primary-color',
        dark: true,
        active: 300,
        inactive: 300,
      },
      {
        title: 'Fornecedores',
        icon: 'fas fa-people-carry',
        amount: 0,
        color: 'accent-color',
        dark: true,
        active: 300,
        inactive: 300,
      },
      {
        title: 'Gravações',
        icon: 'fas fa-stamp',
        amount: 0,
        color: 'success-color',
        dark: true,
        active: 300,
        inactive: 300,
      },
      {
        title: 'Itens de composição',
        icon: 'fas fa-puzzle-piece',
        amount: 0,
        color: 'error-color',
        dark: true,
        active: 300,
        inactive: 300,
      },
    ],
  }),
  methods: {
    getDash() {
      this.$api
        .get(`products/dashboard`, {
          headers: {
            Authorization: `Bearer ${this.$cookies.get('token')}`,
          },
        })
        .then((res) => {
          this.dash = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.setTableItemsPrint()
          this.setProductsCards()
          this.setVendorsCards()
          this.setPrintsCards()
          this.setCompositionsCards()
        })
    },
    setTableItemsPrint() {
      this.tableItems[0].items = this.dash.product_print
      this.tableItems[1].items = this.dash.product_category
    },
    setProductsCards() {
      this.cards[0].amount = this.dash.products.amount
      this.cards[0].active = this.dash.products.active
      this.cards[0].inactive = this.dash.products.inactive
    },
    setVendorsCards() {
      this.cards[1].amount = this.dash.vendors.amount
      this.cards[1].active = this.dash.vendors.active
      this.cards[1].inactive = this.dash.vendors.inactive
    },
    setPrintsCards() {
      this.cards[2].amount = this.dash.prints.amount
      this.cards[2].active = this.dash.prints.active
      this.cards[2].inactive = this.dash.prints.inactive
    },
    setCompositionsCards() {
      this.cards[3].amount = this.dash.compositions.amount
      this.cards[3].active = this.dash.compositions.active
      this.cards[3].inactive = this.dash.compositions.inactive
    },
  },
  created() {
    this.getDash()
  },
}
</script>
